// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yRemoteSelection {
    background-color: rgb(250, 129, 0, .5)
}
.yRemoteSelectionHead {
    position: absolute;
    border-left: orange solid 2px;
    border-top: orange solid 2px;
    border-bottom: orange solid 2px;
    height: 100%;
    box-sizing: border-box;
}
.yRemoteSelectionHead::after {
    position: absolute;
    content: ' ';
    border: 3px solid orange;
    border-radius: 4px;
    left: -4px;
    top: -5px;
}`, "",{"version":3,"sources":["webpack://./src/awareness.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,4BAA4B;IAC5B,+BAA+B;IAC/B,YAAY;IACZ,sBAAsB;AAC1B;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,wBAAwB;IACxB,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb","sourcesContent":[".yRemoteSelection {\n    background-color: rgb(250, 129, 0, .5)\n}\n.yRemoteSelectionHead {\n    position: absolute;\n    border-left: orange solid 2px;\n    border-top: orange solid 2px;\n    border-bottom: orange solid 2px;\n    height: 100%;\n    box-sizing: border-box;\n}\n.yRemoteSelectionHead::after {\n    position: absolute;\n    content: ' ';\n    border: 3px solid orange;\n    border-radius: 4px;\n    left: -4px;\n    top: -5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
