// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4px;
    
}

#room {
    padding: 0.5em;
}
#vim-statusbar {
    margin: 0px;
    padding: 0.5em;
    font-family: monospace;
    flex-grow: 2;
}`, "",{"version":3,"sources":["webpack://./src/editor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;;AAEhB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,WAAW;IACX,cAAc;IACd,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":["#header {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    padding: 4px;\n    \n}\n\n#room {\n    padding: 0.5em;\n}\n#vim-statusbar {\n    margin: 0px;\n    padding: 0.5em;\n    font-family: monospace;\n    flex-grow: 2;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
