// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: sans-serif;

}

.candy-root {
  --candy-color-background1: hsl(160 50% 10%);
  --candy-color-background2: hsl(160 50% 20%);
  --candy-color-background3: hsl(160 50% 17%);
  --candy-color-background4: hsl(160 50% 14%);
  --candy-color-text1: hsl(160 50% 90%);
  --candy-color-text2: hsl(160 50% 70%);
  --candy-color-border1: hsl(160 50% 45%);
  --candy-color-border2: hsl(160 50% 26%);
  --candy-color-border3: hsl(160 50% 22%);
  --candy-color-accent-background1: hsl(85 80% 50%);
  --candy-color-accent-background2: hsl(85 80% 45%);
  --candy-color-accent-border1: hsl(85 80% 80%);
  --candy-color-accent-text1: hsl(85 80% 5%);
  --candy-color-shadow1: hsl(160 50% 5% / 50%);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;;AAE3B;;AAEA;EACE,2CAA2C;EAC3C,2CAA2C;EAC3C,2CAA2C;EAC3C,2CAA2C;EAC3C,qCAAqC;EACrC,qCAAqC;EACrC,uCAAuC;EACvC,uCAAuC;EACvC,uCAAuC;EACvC,iDAAiD;EACjD,iDAAiD;EACjD,6CAA6C;EAC7C,0CAA0C;EAC1C,4CAA4C;AAC9C","sourcesContent":["body {\n    font-family: sans-serif;\n\n}\n\n.candy-root {\n  --candy-color-background1: hsl(160 50% 10%);\n  --candy-color-background2: hsl(160 50% 20%);\n  --candy-color-background3: hsl(160 50% 17%);\n  --candy-color-background4: hsl(160 50% 14%);\n  --candy-color-text1: hsl(160 50% 90%);\n  --candy-color-text2: hsl(160 50% 70%);\n  --candy-color-border1: hsl(160 50% 45%);\n  --candy-color-border2: hsl(160 50% 26%);\n  --candy-color-border3: hsl(160 50% 22%);\n  --candy-color-accent-background1: hsl(85 80% 50%);\n  --candy-color-accent-background2: hsl(85 80% 45%);\n  --candy-color-accent-border1: hsl(85 80% 80%);\n  --candy-color-accent-text1: hsl(85 80% 5%);\n  --candy-color-shadow1: hsl(160 50% 5% / 50%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
