// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#recentList {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

#newDocButton {
    flex-grow: 1;
}

.doc-title-button {

}

.recentCard {
    margin: 1em;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.doc-preview {
    margin: 0.5em;
    flex-grow: 1;
    overflow: hidden;
    font-size: small;
}`, "",{"version":3,"sources":["webpack://./src/recentView.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,6BAA6B;;AAEjC;;AAEA;IACI,YAAY;AAChB;;AAEA;;AAEA;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":["\n#recentList {\n    display: flex;\n    flex-direction: row;\n    align-items: stretch;\n    flex-wrap: wrap;\n    justify-content: space-evenly;\n\n}\n\n#newDocButton {\n    flex-grow: 1;\n}\n\n.doc-title-button {\n\n}\n\n.recentCard {\n    margin: 1em;\n    width: 200px;\n    height: 200px;\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n}\n\n.doc-preview {\n    margin: 0.5em;\n    flex-grow: 1;\n    overflow: hidden;\n    font-size: small;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
